
<template>
  <div id="app">
    <MarketingNavbar />

    <b-container id="marketing-container" class="mt-5">

    <b-row class="my-3">
      <b-col cols="6" class="text-center">
        <b-img
          :src="require('../assets/feather.jpeg')"
          style="max-width:100%;width:300px;"
        />
      </b-col>
      <b-col cols="6">
        <h1>What if you could gamify your life?</h1>
        <p class="custom-font">When you can remove things from your life that make you feel bad, life begins to feel weightless. like a feather.</p>        
      </b-col>
    </b-row>

    <b-row class="my-3">
      <b-col cols="12">
        <div class="my-5" style="background:lightgray;width:100%;height:1px"></div>
      </b-col>
    </b-row>

        
    <b-row class="my-3">
      <b-col cols="12" >
        <h1 class="">Welcome to Feather!</h1>
        <p class="custom-font">An activity-tracking app that lets you efficiently track your daily life and "gamify" it by adding values to your activities. Accurately tracking your day to day and attributing scores to each activity allows you to see exactly which activities inspire good days, and which correlate to bad ones.</p>
        <p class="custom-font">Accurately tracking your day to day and attributing scores to each activity allows you to see exactly which activities inspire good days, and which correlate to bad ones.</p>
      </b-col>
    </b-row>

    <!-- <b-row class="mt-5 mb-3">
      <b-col cols="12">
        <h5>Track your daily activities so you can determine which contribute to positive days, and which activities detract.</h5>
      </b-col>
    </b-row> -->

    <b-row>
      <b-col cols="12">
        <video
          id="video-1" 
          class="video" 
          ref="video" 
          width="100%" 
          height="auto" 
          autoplay 
          loop
          muted
        >
        <source :src="createVideoDemo" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </b-col>
    </b-row>
    
    <div class="mt-4">
      <b-card>
        <b-card-title>Equation</b-card-title>
        <b-card-text>
          <p class="custom-font">Do more positive activities + less negative activities <b-icon-arrow-right/> more good days <b-icon-arrow-right/> more consistency <b-icon-arrow-right/> more productivity <b-icon-arrow-right/>  more fulfillment <b-icon-arrow-right/> more happiness</p>
        </b-card-text>
      </b-card>
    </div>

        <b-row class="mt-5 mb-3">
      <b-col cols="12">
        <h5>View your life from a 10,000 foot view and see which habits you should get better at, and which you should remove from your routine</h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <video
          id="video-1" 
          class="video" 
          ref="video" 
          width="100%" 
          height="auto" 
          autoplay 
          loop
          muted
        >
        <source :src="calendarDemoVideo" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </b-col>
    </b-row>

    <div class="mt-4 text-center">
      <b-card>
        <b-card-title>Try it now</b-card-title>
        <b-card-text class="custom-font">
          <b-button class="d-inline-block mr-3" variant="primary" @click="register()">Create an account</b-button>
          <b-button class="d-inline-block" variant="primary" @click="login()">Sign in</b-button>
        </b-card-text>
      </b-card>
    </div>


    </b-container>

  </div>
</template>

<script>
// import axios from 'axios'
// import VueCookies from 'vue-cookies'
import MarketingNavbar from './components/MarketingNavbar.vue'

export default { 
  name: 'AppMarketingSite',
  components: {
    MarketingNavbar,
  },
  data() {
    return {  
      calendarDemoVideo: require('../assets/feather-calendar-view-trim.mp4'),
      createVideoDemo: require('../assets/feather-upload-day-video.mp4'),
      // submitActivityUrl: `${process.env.VUE_APP_HOST}/api/submit_activity/`,
    }
  },
  methods: {
    register() {
      location.href = "/accounts/register"
    },
    login() {
      location.href = "/accounts/login"
    }
  }
}
</script>

<style lang="scss" scoped>

h1, h2, h3, h4, h5 {
  font-family: 'Catamaran', sans-serif;
}

.custom-font {
  font-size:1.25rem;
  font-family: 'Catamaran', sans-serif;
  // font-family: 'Outfit', sans-serif;
}

</style>
