import Vue from 'vue'
import Vuex from 'vuex'
// import * as modules from './lib'
import AppMarketingSite from './AppMarketingSite.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { LayoutPlugin, SidebarPlugin } from 'bootstrap-vue'
import VueCookies from 'vue-cookies'
import './assets/custom.scss' // important!

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(LayoutPlugin)
Vue.use(SidebarPlugin)
Vue.use(VueCookies)
Vue.use(Vuex)

if (process.env.VUE_APP_ENV == "DEV") {
  Vue.config.productionTip = true
}


const store = new Vuex.Store({
  // state: {
  // },
  // mutations: {
  // },  
  // modules: {
  //   ...modules
  // }
})

new Vue({
  store,
  render: h => h(AppMarketingSite),
}).$mount('#app-marketing-site')
